import React from "react";
import { VexHeroContainer } from "./styles";

export const VexHero = () => {
  return (
    <VexHeroContainer>
      <div className="vex-container">
        <div className="wrapper-melisma">
          <div className="face">
            <div className="face--face"></div>

            <div className="eyes right">
              <div className="line"></div>
            </div>
            <div className="eyes left">
              <div className="line"></div>
            </div>

            <div className="nose"></div>

            <div className="wrapper--hair">
              <div className="fringe">
                <div className="fringe--1"></div>
                <div className="fringe--2"></div>
                <div className="fringe--3"></div>
                <div className="fringe--4"></div>
                <div className="fringe--5"></div>
              </div>
            </div>

            <div className="wrapper--star">
              <i className="star"></i>
            </div>
          </div>

          <div className="hair">
            <div className="hair--1"></div>
            <div className="hair--2"></div>
          </div>

          <div className="headphone">
            <div className="headphone--base"></div>
            <div className="base--apoio"></div>
            <div className="headphone--base2"></div>
            <div className="headphone--arco"></div>
            <div className="headphone--arco2"></div>
            <div className="base--pino"></div>
            <div className="base--apoio2"></div>
          </div>

          <div className="gola">
            <div className="gola--1"></div>
            <div className="gola--2"></div>
          </div>

          <div className="clothes">
            <div className="clothes--1"></div>
            <div className="clothes--2"></div>
            <div className="clothes--3"></div>

            <div className="legs">
              <div className="legs--base"></div>
              <div className="legs--left"></div>
              <div className="legs--rigth"></div>
            </div>
          </div>

          <div className="bell">
            <div className="bell--1"></div>
            <div className="bell--2"></div>
          </div>

          <div className="long-sleeved">
            <div className="long--1"></div>
            <div className="long--2"></div>
          </div>
        </div>
      </div>
    </VexHeroContainer>
  );
};
