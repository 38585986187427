import styled from "styled-components";

export const VexHeroContainer = styled.div`
  //*~ Color Variables
  --color-bg: #403f3f;
  --color-eye: #6b4809;
  --color-nose: #30122f;
  --color-hair-blue: linear-gradient(99deg, #7bd3ea, #285175);
  --color-hair-pink: linear-gradient(99deg, #ffc6db, #d07495);
  --color-blue-border: #285175;
  --color-blue-border-hard: rgba(40, 81, 117, 0.25);
  --color-pink-border: #d07495;
  --color-eyes-background: linear-gradient(45deg, #E830C9, #94227B);
  --color-face: radial-gradient(
    circle,
    rgb(221, 156, 196) 50%,
    rgb(119, 38, 84) 100%
  );
  --color-headphone: #d8c790;
  --color-headphone-shadow: #b9ac7f;
  --color-base-headphone: #2b2f30;

  //*~ Min. Reset
  @import url("https://fonts.googleapis.com/css?family=Josefin+Sans&display=swap");

  .vex-container {
    position: relative;
    margin: 0;
    width: 100%;
    height: 100%;
    display: flex;
    background-color: var(--color-bg) !important;
  }

  .about {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 18rem;
    .name {
      font-family: "Josefin Sans", sans-serif;
      margin-bottom: 0.3rem;
      border-bottom: 1px solid #c7a1bd;
      color: #ffffff7d;
    }
    .icons {
      display: flex;
      width: 100%;
      justify-content: space-around;
      a {
        color: white;
      }
      .fab {
        cursor: pointer;
        background-image: linear-gradient(45deg, #66b2cd, #e79bb7);
        padding: 0.3rem;
        border-radius: 2rem;
        &:hover {
          background-image: linear-gradient(45deg, #e79bb7, #66b2cd);
        }
      }
    }
  }

  .container {
    padding: 0px 15px;
  }

  .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    @media (min-width: 576px) {
      max-width: 540px;
    }
    @media (min-width: 768px) {
      max-width: 720px;
    }
    @media (min-width: 992px) {
      max-width: 960px;
    }
    @media (min-width: 1200px) {
      max-width: 1140px;
    }
  }

  //*~ Placeholder
  %gola {
    border: solid 0.5rem #c31d1d;
    position: absolute;
    height: 46px;
  }

  %star {
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    top: 0.6em;
    left: -1em;
    border-right: 1em solid transparent;
    border-bottom: 0.7em solid black;
    border-left: 1em solid transparent;
  }

  //*~ Base

  img {
    width: 100%;
    opacity: 1;
  }
  .align-center {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 100vh;
    align-items: center;
  }

  .wrapper-melisma {
    width: 23rem;
    display: flex;
    justify-content: center;
    align-content: center;
    top: 0px;
    background-color: rgb(0 0 0 / 0%);
    color: rgb(0 0 0 / 0%);
    height: 0;
  }

  //*~ Face
  .face {
    width: 6.8rem;
    height: 7rem;
    position: absolute;
    top: 5rem;
    border-radius: 4rem 4rem 2rem 3rem;
    transform: rotate(3.7deg);
    overflow: hidden;
    z-index: 5;
    background: var(--color-face);
    animation: 1s sing-face infinite;
    .eyes {
      overflow: hidden;
      &.left {
        left: 2.4rem;
        width: 2.7rem;
        height: 1.3rem;
        background: var(--color-eyes-background);
        position: absolute;
        top: 3.9rem;
        border-radius: 0 0 3rem 3rem;
        transform: skew(-6deg, -2deg);
        border: 2px solid black;
        .line {
          width: 1.4rem;
          height: 1.3rem;
          border-radius: 2rem;
          background-color: var(--color-eye);
          position: absolute;
          left: 0.9rem;
          top: -0.4rem;
          transform: skew(10.1deg, 0);
          border: 2px solid black;
          &:after {
            content: "";
            width: 1.1rem;
            height: 0.8rem;
            background-color: black;
            position: absolute;
            border-radius: 0.3rem 0.2rem 1rem 1rem;
            left: 0.1rem;
            top: 0.3rem;
          }
          &:before {
            z-index: 9;
            content: "";
            width: 0;
            height: 0;
            position: absolute;
            box-shadow: 6px 1px 4px 3px white;
            top: 0.4rem;
            left: -0.2rem;
          }
        }
      }
      &.right {
        left: -0.4rem;
        width: 1rem;
        height: 1.1rem;
        background: var(--color-eyes-background);
        position: absolute;
        top: 3.9rem;
        border-radius: 0 0 3rem 3rem;
        transform: skew(-7deg, 11deg) rotateY(129deg);
        border: 2px solid black;
        .line {
          background-color: var(--color-eye);
          width: 0.74rem;
          height: 0.9rem;
          border-radius: 2rem;
          position: absolute;
          left: -0.1rem;
          top: -0.2rem;
          transform: skew(-4.5deg, 0);
          border: 2px solid black;
          &:after {
            content: "";
            width: 1.1rem;
            height: 0.8rem;
            background-color: black;
            position: absolute;
            border-radius: 0.3rem 0.2rem 0.6rem 1rem;
            left: -0.5rem;
            top: 0rem;
          }
          &:before {
            z-index: 9;
            content: "";
            width: 0;
            height: 0;
            position: absolute;
            box-shadow: 14px -1px 4px 2px white;
            top: 0.4rem;
            left: -0.2rem;
          }
        }
      }
    }
    .nose {
      position: absolute;
      margin: auto;
      top: 3rem;
      right: 4.8rem;
      bottom: 0;
      left: 0;
      background-color: var(--color-nose);
      height: 0.3rem;
      width: 0.3rem;
      transform: rotate(-38deg) skewX(-11deg);
      &:after {
        background-color: var(--color-nose);
        content: "";
        border-radius: 50%;
        position: absolute;
        width: 0.3rem;
        height: 0.3rem;
        top: 0px;
        left: 0.2rem;
        transform: skewX(40deg);
      }
      &:before {
        content: "";
        background-color: var(--color-nose);
        border-radius: 50%;
        position: absolute;
        width: 0.3rem;
        height: 0.3rem;
        top: -0.2rem;
        left: 0px;
      }
    }
    .star {
      position: absolute;
      display: inline-block;
      width: 0;
      height: 0;
      margin-left: 0.9em;
      margin-right: 0.9em;
      margin-bottom: 1.2em;
      border-right: 0.3em solid transparent;
      border-bottom: 0.7em solid black;
      border-left: 0.3em solid transparent;
      font-size: 0.4rem;
      left: 3.2rem;
      top: 3.1rem;
      transform: skew(-8deg, 3deg);
      &:before {
        content: "";
        @extend %star;
        transform: rotate(-35deg);
      }
      &:after {
        content: "";
        @extend %star;
        transform: rotate(35deg);
      }
    }
  }

  //*~ Fringe
  .fringe {
    .fringe--1 {
      width: 0.7rem;
      height: 2.65rem;
      top: -0.2rem;
      left: 0.7rem;
      background: var(--color-hair-blue);
      border-top-left-radius: 2rem;
      transform: rotate(-3deg) skew(-35deg, 13deg);
      border: 0.2px solid var(--color-blue-border);
      position: absolute;
    }
    .fringe--2 {
      width: 2.7rem;
      height: 2.9rem;
      top: -0.1rem;
      left: 0.7rem;
      background: var(--color-hair-pink);
      border-top-left-radius: 11rem;
      transform: rotate(-3deg) skew(-15deg, 14deg);
      border: 0.5px solid #d07495;
      position: absolute;
      &:after {
        width: 11px;
        height: 6px;
        border: solid 0.08rem #c31d1d;
        border-color: #d486ab transparent transparent transparent;
        border-radius: 155%/86% 149% 0 0;
        transform: rotate(91deg);
        top: 2.25rem;
        z-index: 999;
        left: 0rem;
        content: "";
        position: absolute;
      }
    }
    .fringe--3 {
      width: 2.7rem;
      height: 3.08rem;
      border-top-left-radius: 2rem;
      position: absolute;
      background: var(--color-hair-blue);
      top: -0.1rem;
      transform: rotate(-3deg) skew(-15deg, 13deg);
      left: 1.7rem;
      border: 0.5px solid var(--color-blue-border);
      &:before {
        width: 11px;
        height: 6px;
        border: solid 0.08rem #c31d1d;
        border-color: #28517559 transparent transparent transparent;
        border-radius: 155%/86% 149% 0 0;
        transform: rotate(-80deg);
        top: 2.45rem;
        z-index: 999;
        left: 0.2rem;
        content: "";
        position: absolute;
      }
      &:after {
        width: 11px;
        height: 6px;
        border: solid 0.08rem #c31d1d;
        border-color: #28517559 transparent transparent transparent;
        border-radius: 155%/86% 149% 0 0;
        transform: rotate(91deg);
        top: 2.45rem;
        z-index: 999;
        left: 0.5rem;
        content: "";
        position: absolute;
      }
    }
    .fringe--4 {
      width: 1.7rem;
      height: 3.2rem;
      border-top-left-radius: 20rem;
      position: absolute;
      background: var(--color-hair-pink);
      top: 0rem;
      transform: rotate(-3deg) skew(-15deg, 12.7deg);
      left: 3.3rem;
      border: 0.5px solid #d07495;
    }
    .fringe--5 {
      width: 2.7rem;
      height: 3.65rem;
      position: absolute;
      background: var(--color-hair-blue);
      top: -0.1rem;
      transform: rotate(-14deg) skew(-15deg, 24deg);
      left: 4.7rem;
      border: 0.5px solid var(--color-blue-border);
      &:after {
        width: 39px;
        height: 11px;
        border: solid 0.08rem #c31d1d;
        border-color: var(--color-blue-border)-hard transparent transparent
          transparent;
        border-radius: 155%/86% 149% 0 0;
        transform: rotate(78deg);
        top: 2.35rem;
        z-index: 999;
        left: -0.6rem;
        content: "";
        position: absolute;
      }
    }
  }

  //*~ Hair
  .hair {
    left: 0;
    z-index: 2;
    transform: rotate(0deg);
    position: absolute;
    animation: 1s sing-hair infinite;
    .hair--1 {
      width: 67px;
      height: 29px;
      top: 5.5rem;
      left: 14.1rem;
      border-radius: 65%;
      box-shadow: 20px 4px 0 0 #e19cc0;
      transform: skew(-49deg, 35deg) rotate(-195deg);
      position: absolute;
      filter: drop-shadow(1px 2px 0px #b36682);
      z-index: 1;
      &:after {
        content: "";
        width: 52px;
        height: 73px;
        top: -2.2rem;
        left: -1.3rem;
        border-radius: 65%;
        box-shadow: 20px -18px 0 0 #5a9fbb;
        transform: skew(-57deg, 32deg) rotateX(-58deg);
        position: absolute;
        filter: drop-shadow(-2px 2px 0px #578dab);
      }
    }
    .hair--2 {
      width: 54px;
      height: 48px;
      top: 9.7rem;
      left: 13.3rem;
      border-radius: 65%;
      box-shadow: 20px 4px 0 0 #4d8caa;
      transform: skew(-51deg, 53deg) rotate(-125deg);
      filter: drop-shadow(2px 2px 0px #35718d);
      position: absolute;
    }
  }

  //*~ Headphone
  .headphone {
    .headphone--base {
      width: 2.2rem;
      height: 3rem;
      top: 9rem;
      left: 14rem;
      background-color: var(--color-headphone);
      border-radius: 3rem;
      box-shadow: -5px 1px #9d8c6a;
      position: absolute;
      z-index: 10;
      filter: drop-shadow(-5px 0px 0px #d7d2b4);
      animation: 1s sing-headphone infinite;
      &:after {
        content: "";
        width: 2rem;
        height: 2.3rem;
        background-color: white;
        position: absolute;
        left: 0.3rem;
        border-radius: 2rem;
        top: 0.3rem;
      }
    }
    .base--apoio {
      width: 0.9rem;
      height: 2rem;
      top: 7.6rem;
      left: 14.8rem;
      background: var(--color-base-headphone);
      border-radius: 2rem;
      transform: rotate3d(1, 1, 3, 96deg);
      position: absolute;
      z-index: 999999;
      &:after {
        background: #3e4345;
        width: 0.8rem;
        height: 0.8rem;
        position: absolute;
        border-radius: 2rem;
        transform: rotate3d(1, 1, 3, 20deg);
        top: 1.3rem;
        left: 0rem;
        content: "";
      }
    }
    .headphone--arco {
      width: 100px;
      height: 46px;
      border: solid 0.5rem #c31d1d;
      border-color: var(--color-headphone) transparent transparent transparent;
      border-radius: 84%/100% 149% 0 0;
      transform: rotate(54deg);
      top: 4.75rem;
      z-index: 999;
      left: 9.7rem;
      position: absolute;
      &:after {
        width: 100px;
        height: 46px;
        border: solid 0.5rem #c31d1d;
        border-color: var(--color-headphone)-shadow transparent transparent
          transparent;
        border-radius: 84%/71% 149% 0 0;
        transform: rotate(7deg);
        top: -0.15rem;
        z-index: 999;
        left: -0.3rem;
        content: "";
        position: absolute;
      }
      &:before {
        width: 52px;
        height: 82px;
        border: solid 0.5rem #c31d1d;
        border-color: var(--color-headphone)-shadow transparent transparent
          transparent;
        border-radius: 283%/80% 109% 0 0;
        transform: rotate(-70deg);
        top: -0.45rem;
        z-index: 999;
        left: 0.3rem;
        content: "";
        position: absolute;
      }
    }
    .headphone--arco2 {
      width: 83px;
      height: 68px;
      border: solid 0.55rem #c31d1d;
      border-color: #706155 transparent transparent transparent;
      border-radius: 321%/139% 342% 0 0;
      transform: rotate(-38deg);
      top: 3.95rem;
      z-index: 1;
      left: 8.8rem;
      position: absolute;
      &:after {
        content: "";
        width: 45px;
        height: 82px;
        border: solid 0.5rem #c31d1d;
        border-color: var(--color-headphone)-shadow transparent transparent
          transparent;
        border-radius: 184%/35% 61% 0 0;
        transform: rotate(-14deg);
        top: -0.85rem;
        z-index: 1;
        left: 0.2rem;
        position: absolute;
      }
    }
    .base--pino {
      background: var(--color-base-headphone);
      width: 0.8rem;
      height: 1rem;
      position: absolute;
      border-radius: 2rem;
      transform: rotate3d(1, 1, 3.1, 175deg);
      top: 6.2rem;
      z-index: 999999;
      left: 8rem;
    }
    .base--apoio2 {
      width: 2rem;
      height: 2.9rem;
      top: 6.9rem;
      left: 7.4rem;
      background-color: var(--color-headphone);
      animation: 1s sing-face infinite;
      border-radius: 2rem 0.3rem 0.3rem 1rem;
      transform: rotate(10deg) skew(3deg, 14deg);
      filter: drop-shadow(-7px 4px 0px #90855e);
      position: absolute;
      z-index: 1;
    }
  }

  //~* Gola
  .gola {
    z-index: 3;
    .gola--1 {
      @extend %gola;
      width: 131px;
      border-color: #9d7c59 transparent transparent transparent;
      border-radius: 81%/72.8% 141.6% 0 0;
      animation: 1s sing-gola2 infinite;
      transform: rotate(186deg);
      top: 8.85rem;
      z-index: 999;
      left: 6.8rem;
    }
    .gola--2 {
      @extend %gola;
      width: 120px;
      border-color: #ac8e60 transparent transparent transparent;
      border-radius: 81%/72.8% 123.9% 0 0;
      transform: rotate(187deg);
      animation: 1s sing-gola infinite;
      top: 8.65rem;
      z-index: 1;
      left: 7.2rem;
    }
    &:before {
      content: "";
      width: 2.6rem;
      height: 2.1rem;
      border-radius: 37rem;
      position: absolute;
      top: 10.3rem;
      background-color: #29253c;
      left: 8.2rem;
      transform: rotateZ(31deg);
      z-index: 1;
    }
  }

  //*~ Clothes
  .clothes {
    z-index: 2;
    .clothes--1 {
      width: 2.6rem;
      height: 3.5rem;
      border-radius: 2rem 0 0 2rem;
      transform: rotate(-20deg);
      position: absolute;
      top: 11.9rem;
      left: 10.9rem;
      background-image: radial-gradient(#d2d2e7, #666996);
      border: 5px solid #2d2538;
      z-index: 10;
    }
    .clothes--2 {
      width: 1.2rem;
      height: 3.6rem;
      border-radius: 0 2rem 2rem 0;
      position: absolute;
      top: 12.1rem;
      left: 8.9rem;
      background-image: radial-gradient(#d2d2e7, #666996);
      border: 4px solid #2d2538;
      transform: rotate(0deg) skew(-12deg, -10deg);
      z-index: 9;
      &:after {
        content: "";
        width: 0;
        height: 0;
        left: -0.3rem;
        top: 0.5rem;
        border-top: 7px solid transparent;
        border-left: 10px solid var(--color-bg);
        border-bottom: 17px solid transparent;
        position: absolute;
      }
    }
    .clothes--3 {
      content: "";
      width: 1.9rem;
      height: 2.8rem;
      border-radius: 0 2rem 2rem 0;
      position: absolute;
      top: 14.5rem;
      left: 8.6rem;
      background-image: radial-gradient(#d2d2e7, #666996);
      border: 4px solid #2d2538;
      transform: rotate(125deg);
      z-index: 1;
    }
  }

  //*~ Sino
  .bell {
    z-index: 10;
    .bell--1 {
      border-bottom: 1rem solid #aea17d;
      border-left: 0.3rem solid transparent;
      border-right: 0.1rem solid transparent;
      height: 0;
      width: 1rem;
      transform: rotate(0deg);
      position: absolute;
      top: 12.7rem;
      left: 9.6rem;
      &:after {
        content: "";
        width: 0.48rem;
        height: 1rem;
        background-color: #776859;
        position: absolute;
        left: 0.6rem;
        transform: rotate(-9deg);
      }
      &:before {
        width: 14px;
        height: 46px;
        border: solid 0.2rem #c31d1d;
        border-color: #776859 transparent transparent transparent;
        border-radius: 155%/86% 149% 0 0;
        transform: rotate(269deg);
        top: -1.55rem;
        z-index: 999;
        left: 1.1rem;
        content: "";
        position: absolute;
      }
    }
    .bell--2 {
      height: 1rem;
      width: 1rem;
      transform: rotate(0deg);
      position: absolute;
      top: 13.7rem;
      left: 9.6rem;
      background-color: #aea17d;
      box-shadow: 7px 0px #776859;
      &:after {
        content: "";
        width: 0.9rem;
        height: 0.6rem;
        background-color: #605244;
        position: absolute;
        top: 0.3rem;
      }
    }
  }

  //*~ Legs
  .legs {
    .legs--base {
      width: 3.3rem;
      height: 3rem;
      position: absolute;
      border-radius: 6rem;
      background-image: radial-gradient(#332e49, #1a1827);
      top: 13.7rem;
      left: 9.5rem;
      z-index: 8;
      border: 1px solid #0e0d1c;
    }
    .legs--left {
      width: 1.7rem;
      height: 3rem;
      background-color: #242135;
      position: absolute;
      top: 15rem;
      border-radius: 0 0 53% 53%;
      left: 11.5rem;
      transform: rotate(-12deg);
    }
    .legs--rigth {
      position: relative;
      background-color: #242135;
      text-align: left;
      width: 1.5rem;
      height: 1.5rem;
      border-top-right-radius: 30%;
      transform: rotate(3deg) skewX(-30deg) scale(1, 0.866);
      top: 15.3rem;
      left: -0.7rem;
      z-index: 5;
      &:after {
        content: "";
        position: absolute;
        background-color: inherit;
        width: 2rem;
        height: 2rem;
        border-top-right-radius: 30%;
        transform: rotate(139.6deg) skewY(-45deg) scale(0.707, 1.414)
          translate(50%);
        top: -0.7rem;
        left: -0.1rem;
      }
      &:before {
        content: "";
        position: absolute;
        background-color: inherit;
        width: 1.6rem;
        height: 1.5rem;
        border-top-right-radius: 30%;
        transform: rotate(-115deg) skewX(-45deg) scale(1.414, 0.707)
          translate(0, -50%);
      }
    }
  }

  //*~ Long sleeved
  .long-sleeved {
    .long--1 {
      width: 1.5rem;
      height: 4rem;
      position: absolute;
      left: 13rem;
      top: 12.3rem;
      transform: rotate(-2deg) skew(4deg, 10deg);
      z-index: 2;
      border-top-right-radius: 1rem;
      border-left: 2px solid #8c8eb3;
      border-right: 2px solid #8c8eb3;
      background-image: linear-gradient(#d2d2e7, #989abc);
      &:after {
        width: 58px;
        height: 6px;
        border: solid 0.38rem #c31d1d;
        border-color: #4c4856 transparent transparent transparent;
        border-radius: 155%/86% 101% 0 0;
        transform: rotate(86deg);
        top: 1.65rem;
        z-index: 66;
        left: -1.9rem;
        content: "";
        position: absolute;
      }
    }
    .long--2 {
      width: 3.5rem;
      height: 4rem;
      border-radius: 3rem;
      position: absolute;
      top: 14.2rem;
      left: 13.2rem;
      z-index: 1;
      background-image: linear-gradient(#d2d2e7, #666996);
      &:after {
        width: 53px;
        height: 172px;
        border: solid 0.38rem #c31d1d;
        border-color: #4c4856 transparent transparent transparent;
        border-radius: 109%/140% 101% 0 0;
        transform: rotate(210deg);
        top: -7.35rem;
        z-index: 66;
        left: 2.1rem;
        content: "";
        position: absolute;
      }
      &:before {
        width: 44px;
        height: 172px;
        border: solid 0.38rem #c31d1d;
        border-color: #4c4856 transparent transparent transparent;
        border-radius: 109%/66% 119% 0 0;
        transform: rotate(66deg);
        top: -1.54rem;
        z-index: 66;
        left: -3.9rem;
        content: "";
        position: absolute;
      }
    }
  }

  @keyframes sing-face {
    0% {
      transform: rotate(3.7deg);
    }
    50% {
      transform: rotate(-5.3deg);
    }
    100% {
      transform: rotate(3.7deg);
    }
  }

  @keyframes sing-headphone {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(10deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

  @keyframes sing-gola {
    0% {
      transform: rotate(187deg);
    }
    50% {
      transform: rotate(182deg);
    }
    100% {
      transform: rotate(187deg);
    }
  }

  @keyframes sing-gola2 {
    0% {
      transform: rotate(186deg);
    }
    50% {
      transform: rotate(183deg);
    }
    100% {
      transform: rotate(186deg);
    }
  }

  @keyframes sing-hair {
    0% {
      transform: rotate(2deg);
    }
    50% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(2deg);
    }
  }
`;
