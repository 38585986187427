import CustomPage from "@/components/CustomPage";
import { VexHero } from "@/components/VexHero";
import { SectionContainer } from "@/components/SectionContainer";
import { CustomAcquireButton } from "@/components/CustomAcquireButton";
import useScreenSize from "@/hooks/useScreenSize";

export default function Custom404() {
  const { isMobile } = useScreenSize();

  return (
    <CustomPage
      canonicalUrl={`https://elojobrise.com/404`}
      title="ELOJOB RISE - COACH"
    >
      <SectionContainer
        marginTop={isMobile ? 120 : 160}
        flexDirection="column"
        minHeight={isMobile ? "50vh" : "42vh"}
        style={{ alignItems: "center" }}
      >
        <h1>A Vex não encontrou a página solicitada</h1>
        <VexHero />
        <CustomAcquireButton link="/" customcolor="#d07495">
          Voltar a página principal
        </CustomAcquireButton>
      </SectionContainer>
    </CustomPage>
  );
}
